import * as React from 'react';
import gReview from '../media/images/reviews/googlereview.webp';
import gReview_FALLBACK from '../media/images/reviews/googlereview.png';
import tReview from '../media/images/reviews/trustpilot.webp';
import tReview_FALLBACK from '../media/images/reviews/trustpilot.png';
import Slider from "react-animated-slider";
import 'react-animated-slider/build/horizontal.css';
import avatar_image_1 from "../media/images/reviews/drashraf-nobg.webp";
import avatar_image_1_FALLBACK from "../media/images/reviews/drashraf-nobg.png";
import avatar_image_2 from "../media/images/reviews/jpinder-nobg.webp";
import avatar_image_2_FALLBACK from "../media/images/reviews/jpinder-nobg.png";
import avatar_image_3 from "../media/images/reviews/GRT-nobg.webp";
import avatar_image_3_FALLBACK from "../media/images/reviews/GRT-nobg.png";
import {GREVIEW} from "../constants/polarisData";
const ReviewSection = () => {
    const slides = [
        {
            author: 'Mindful Aesthetics',
            avatar: avatar_image_1,
            avatar_FALLACK: avatar_image_1_FALLBACK,
            quote: '“Polaris effectively transformed our business requirements into engaging visual narratives, resulting in increased sales and higher audience engagement.”',
        },
        {
            author: 'GRT Charity',
            avatar: avatar_image_3,
            avatar_FALLACK: avatar_image_3_FALLBACK,
            quote: '“They created content for my campaign 2 years ago. That video got us over £70k in less than 1 hour. It was the best video we\'ve ever done.”',
        },
        {
            author: 'Julian Pinder',
            avatar: avatar_image_2,
            avatar_FALLACK: avatar_image_2_FALLBACK,
            quote: '“Their content was both creative and cost-effective, impressing us with their ability to deliver high-quality results. We eagerly anticipate collaborating with Polaris again in the future.”',
        }
    ];
    return (
        <>
            <div className={'spacer-25'}>&nbsp;</div>
            <div className={'review-title'}>
                <h1>Exceeding your expectations</h1>
            </div>
            <Slider autoplay={6000} touchDisabled={true}>
                {slides.map((slide, index) =>
                    <div className={'quote-review-container'}  key={index}>
                        <div className={'quote-review'}>
                            <picture>
                                <source srcSet={slide.avatar} type="image/webp" />
                                <img className="badge badge-avatar badge-size" src={slide.avatar_FALLACK}  alt={'profile'}/>
                            </picture>
                            <p>{slide.quote}</p>
                            <div className={'quote-review-button'}>
                                <h2>{slide.author}</h2>
                            </div>
                        </div>
                    </div>)}
            </Slider>
            <div className={'image-reviews-container'}>
                <div className={'google-review-container'}>
                    <a href={GREVIEW} target="_blank" rel="noopener noreferrer">
                    <picture>
                        <source srcSet={gReview} type="image/webp" />
                        <img src={gReview_FALLBACK} alt={'google review'}/>
                    </picture>
                    </a>
                </div>
                <div className={'google-review-container'}>
                    <a href={GREVIEW} target="_blank" rel="noopener noreferrer">
                        <picture>
                            <source srcSet={tReview} type="image/webp"/>
                            <img src={tReview_FALLBACK} alt={'trust pilot review'}/>
                        </picture>
                    </a>
                </div>
            </div>
            <div className={'spacer-5'}>&nbsp;</div>
        </>
    )
}
export default ReviewSection;
