import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Scroll, {Link} from 'react-scroll';
import {debounce} from 'lodash';
import {CarouselProvider, Slide, Slider} from 'pure-react-carousel';
import section_project_0 from '../media/images/projects/showreel.webp';
import section_project_0_FALLBACK from '../media/images/projects/showreel.png';
import section_project_1 from '../media/images/projects/section_project_3.webp';
import section_project_1_FALLBACK from '../media/images/projects/section_project_3.png';
import section_project_2 from '../media/images/projects/section_project_1.webp';
import section_project_2_FALLBACK from '../media/images/projects/section_project_1.png';
import section_project_3 from '../media/images/projects/section_project_2.webp';
import section_project_3_FALLBACK from '../media/images/projects/section_project_2.png';
import section_project_4 from '../media/images/projects/section_project_4.webp';
import section_project_4_FALLBACK from '../media/images/projects/section_project_4.png';
/*import section_project_5 from '../media/images/slabs/slab_1_alt.webp';
import section_project_5_FALLBACK from '../media/images/slabs/slab_1_alt.png';
 */
import {
    PROJECT_LINK_1,
    PROJECT_LINK_2,
    PROJECT_LINK_3, PROJECT_LINK_4,
    PROJECT_TITLE_0,
    PROJECT_TITLE_1,
    PROJECT_TITLE_2,
    PROJECT_TITLE_3,
    PROJECT_TITLE_4,
    SHOWREEL
} from "../constants/polarisData";
import swipe from '../media/icons/swipeLeft.json';
import {CaretDownOutlined, CaretRightOutlined, HistoryOutlined} from "@ant-design/icons";
import {useInView} from "react-intersection-observer";
import Player from "./reactPlayer";
import Lottie from "lottie-react";
import {Parallax} from "react-scroll-parallax";
import {isMobile} from "react-device-detect";
const Projects = () => {
    const [isHovered, setIsHovered] = useState(false);
    const refAnim = useRef();
    const scroller = Scroll.scroller;
    const projects = [
        {
            title: PROJECT_TITLE_0,
            description: 'A short captivating showreel showcasing our storytelling and creative work across different industries. From elevating brand narratives to creating compelling visuals, our work has left a lasting impression.',//'A short captivating showreel showcasing a glimpse into our latest, innovative video production projects with some of our wonderful clientele.'
            image: section_project_0,
            imageFallback: section_project_0_FALLBACK,
            url: SHOWREEL,
            time: '0:17'
        },
        {
            title: PROJECT_TITLE_4,
            description: 'This vibrant commercial showcases our client\'s gourmet smashed burgers. Sizzling close-ups, mouthwatering visuals, fresh ingredients, and dynamic music capture their unique takeaway, inviting viewers to indulge in an unforgettable burger experience.',
            image: section_project_4,
            imageFallback: section_project_4_FALLBACK,
            url: PROJECT_LINK_4,
            time: '0:41'
        },
        {
            title: PROJECT_TITLE_1,
            description: 'Enter the delectable world of Cookie Courier, where our tasteful commercial not only unveiled their irresistible cookies but also played a key role in propelling this culinary venture to success.',
            image: section_project_1,
            imageFallback: section_project_1_FALLBACK,
            url: PROJECT_LINK_1,
            time: '0:59'
        },
        {
            title: PROJECT_TITLE_2,
            description: 'Explore the intricate world of craftsmanship in The Luthier. This short documentary offers an intimate look into the life and passion of a skilled luthier, capturing the delicate artistry involved in crafting exquisite musical instruments.',
            image: section_project_2,
            imageFallback: section_project_2_FALLBACK,
            url: PROJECT_LINK_2,
            time: '4:01'
        },
        {
            title: PROJECT_TITLE_3,
            description: '\'What is Greatness?\' - our impactful TV commercial for Global Relief Trust. This ad captures the essence of everyday acts of greatness, portraying the beauty of mundane kindness. Within just one hour of going live, the response was overwhelming, an impressive £70,000.',
            image: section_project_3,
            imageFallback: section_project_3_FALLBACK,
            url: PROJECT_LINK_3,
            time: '0:51'
        }
        /*
        {
            title: PROJECT_TITLE_5,
            description: 'Years following the events of The Shining (1980), a now-adult Dan Torrance must protect a young girl with similar powers from a cult known as The True Knot, who prey on children with powers to remain immortal.',
            image: section_project_5,
            imageFallback: section_project_5_FALLBACK,
            url: PROJECT_LINK_5,
            time: '6:69'
        }*/
    ];
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [screenSizeHeight, setScreenSizeHeight] = useState(window.innerHeight);
    const [slidesVisible, setSlidesVisible] = useState(3);
    const [mainImage, setMainImage] = useState(projects[0].image);
    const [mainImageFallback, setMainImageFallback] = useState(projects[0].imageFallback);
    const [mainTitle, setMainTitle] = useState(projects[0].title);
    const [mainDescription, setMainDescription] = useState(projects[0].description);
    const [mainUrl, setMainUrl] = useState(projects[0].url);
    const [mainTime, setMainTime] = useState(projects[0].time);
    const [isShowing, setIsShowing] = useState(false);
    const [isPlayerInView, setPlayerInView] = useState(true);
    const handlePlayClick = () => {
        setIsShowing(true);
        scroller.scrollTo('section-project', {
            duration: 1500,
            delay: 200,
            smooth: true
        });
    };
    const handleCloseClick = () => {
        setIsShowing(false);
        scroller.scrollTo('title-project', {
            duration: 1500,
            delay: 200,
            smooth: true
        });
    };
    const exitPlayerClick = () => {
        setIsShowing(false);
    };
    const options = {
        threshold: 0.3,
    }
    const [ ref, inView ] = useInView(options);
    const [ ref2, inView2 ] = useInView(options);
    const [ ref3, inView3 ] = useInView(options);
    const handleResize = debounce(() => {
        setScreenSize(window.innerWidth);
        setScreenSizeHeight(window.innerHeight);
        }, 250);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const defaultOptions = {
        loop: true,
        autoplay: false,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    let hoverTimeout;
    const handleHover = (index) => {
        const project = projects[index];
        console.log(project.title);
        setMainImage(project.image);
        setMainImageFallback(project.imageFallback);
        setMainTitle(project.title);
        setMainDescription(project.description);
        setMainUrl(project.url);
        setMainTime(project.time);
    };
    const handleMouseOver = (index) => {
        hoverTimeout = setTimeout(() => {
            handleHover(index);
        }, 250);
    };
    const handleMouseOut = () => {
        clearTimeout(hoverTimeout);
    };
    useEffect(() => {
        return () => {
            clearTimeout(hoverTimeout);
        };
    }, [hoverTimeout]);
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);
    useEffect(() => {
        if(inView) {
            setPlayerInView(false);
        } else {
            setPlayerInView(true);
        }
    }, [inView]);
    useEffect(() => {
        if(!inView2) {
            refAnim.current?.pause();
        } else if(!isHovered){
            refAnim.current?.play();
        }
    }, [inView2, isHovered]);
    useEffect(() => {
        if (inView3 && !isHovered) {
            setTimeout(() => {
                setIsHovered(true)
            }, 5000);
        }
    }, [inView3, isHovered]);
    useEffect(() => {
        switch (true) {
            case screenSize >= 3500 && screenSizeHeight >= 1300:
                setSlidesVisible(5.5);
                break;
            case screenSize >= 3350 && screenSizeHeight <= 1440:
                setSlidesVisible(8.5);
                break;
            case screenSize >= 3000 && screenSizeHeight >= 1300:
                setSlidesVisible(4.5);
                break;
            case screenSize >= 2500 && screenSizeHeight >= 1300:
                setSlidesVisible(4);
                break;
            case screenSize >= 2200 && screenSizeHeight >= 1300:
                setSlidesVisible(3.5);
                break;
            case screenSize >= 1900 && screenSizeHeight >= 1300:
                setSlidesVisible(3);
                break;
            case screenSize >= 1700 && screenSizeHeight >= 1300:
                setSlidesVisible(2.5);
                break;
            case screenSize >= 1200 && screenSizeHeight >= 1300:
                setSlidesVisible(3);
                break;
            case screenSize >= 500 && screenSizeHeight >= 1300:
                setSlidesVisible(2.5);
                break;
            case screenSize >= 2700 && screenSizeHeight < 1300:
                setSlidesVisible(7);
                break;
            case screenSize >= 2500 && screenSizeHeight < 1300:
                setSlidesVisible(6);
                break;
            case screenSize >= 2200 && screenSizeHeight < 1300:
                setSlidesVisible(5.5);
                break;
            case screenSize >= 1900 && screenSizeHeight < 1300:
                setSlidesVisible(5);
                break;
            case screenSize >= 1700 && screenSizeHeight < 1300:
                setSlidesVisible(4.5);
                break;
            case screenSize >= 1500 && screenSizeHeight < 1300:
                setSlidesVisible(3.7);
                break;
            case screenSize >= 1270 && screenSizeHeight < 1300:
                setSlidesVisible(3.5);
                break;
            case screenSize >= 1080 && screenSizeHeight < 1300:
                setSlidesVisible(2.8);
                break;
            case screenSize >= 950 && screenSizeHeight < 1300:
                setSlidesVisible(2.5);
                break;
            case screenSize >= 900:
                setSlidesVisible(2.3);
                break;
            case screenSize >= 840:
                setSlidesVisible(2.2);
                break;
            case screenSize >= 710:
                setSlidesVisible(1.9);
                break;
            case screenSize >= 660:
                setSlidesVisible(1.8);
                break;
            case screenSize >= 540:
                setSlidesVisible(1.5);
                break;
            case screenSize >= 520:
                setSlidesVisible(1.2);
                break;
            case screenSize >= 480:
                setSlidesVisible(1.2);
                break;
            case screenSize >= 425:
                setSlidesVisible(1.2);
                break;
            case screenSize >= 400:
                setSlidesVisible(1.2);
                break;
            case screenSize >= 375:
                setSlidesVisible(1.2);
                break;
            case screenSize >= 330:
                setSlidesVisible(1.2);
                break;
            case screenSize >= 0:
                setSlidesVisible(1.2);
                break;
            default:
                setSlidesVisible(3);
        }
    }, [screenSize, screenSizeHeight]);
    useEffect(() => {
        if(isShowing && inView) {
            console.log('isShowing && inView');
            debounce(() => {
                scroller.scrollTo('section-project', {
                        duration: 1500,
                        delay: 250,
                        smooth: true
                    }
                    , 250)});
        } else {
            console.log('ELSE: isShowing && inView');
        }
    },[inView, isShowing, scroller]);
    return(
        <>
            <div className='section-project section-min-height-projects' id='section-project' ref={ref2}>
                <div id={'video-player-react'} ref={ref} className={isShowing ? 'player-wrapper onScreen' : 'player-wrapper offScreen'}>
                    <Player id={'react-player'} url={mainUrl.toString()} muted={isPlayerInView}/>
                    <Link to='projects' smooth={true}>
                        <button className='close-button' onClick={handleCloseClick}><p>CLOSE VIDEO</p></button>
                    </Link>
                    <Link to='contact-us' smooth={false}>
                        <button className='secondary-project-cta' onClick={exitPlayerClick}><p>CONTACT US NOW</p></button>
                    </Link>
                </div>
                <div className='main-project'>
                    <Parallax scale={[1.5, 1]}>
                        <picture>
                            <source src={mainImage} type='image/webp' />
                            <img src={mainImageFallback} alt='preview'/>
                        </picture>
                    </Parallax>
                    <div className={'main-project-info-container'}>
                        <div className={'main-project-title'} id={'title-project'}>
                            <h1>{mainTitle}</h1>
                            <p>{mainDescription}</p>
                            <button className='project-play-button' onClick={handlePlayClick} onDoubleClick={handlePlayClick} >
                                <CaretRightOutlined /> Play
                            </button>
                            <button className='project-info-button' onClick={handlePlayClick} onDoubleClick={handlePlayClick} >
                                <h4><HistoryOutlined /> {mainTime}</h4>
                            </button>
                        </div>
                        <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={100}
                            orientation={'horizontal'}
                            playDirection={'forward'}
                            currentSlide={0}
                            visibleSlides={slidesVisible}
                            totalSlides={projects.length}
                            touchEnabled={true}
                            dragEnabled={true}
                            dragStep={1}
                            isPlaying={false}
                            infinite={true}
                            lockOnWindowScroll={true}
                            className={'sub-carousel-pos'}
                        >
                            <Parallax translateX={[0, -5]} translateY={0} speed={10}>
                                <Slider preventVerticalScrollOnTouch={true}>
                                    {projects.map((project, index) => (
                                        <Slide key={index} index={index}>
                                            <div className={'sub-project-slab'}
                                                 onMouseOver={() => handleMouseOver(index)}
                                                 onMouseOut={handleMouseOut}
                                                 onDoubleClick={handlePlayClick}>
                                                <picture>
                                                    <source src={project.image} type='image/webp' />
                                                    <img src={project.imageFallback} alt={project.title} />
                                                </picture>
                                            </div>
                                        </Slide>
                                    ))}
                                </Slider>
                                {isMobile ?
                                    <>
                                        <div ref={ref3} className={isHovered ? 'display-none-swipe' : 'swipe-anim-projects'} onTouchMove={handleMouseEnter} onTouchStart={handleMouseEnter} onMouseOver={handleMouseEnter} onMouseEnter={handleMouseEnter}>
                                            <Lottie lottieRef={refAnim} options={defaultOptions} animationData={swipe}/>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                            </Parallax>
                        </CarouselProvider>
                    </div>
                    <div className='main-project-bottom-buttons'>
                        <Link className='slab5-project slab5-project-why' to='contact-us' smooth={false}>
                            <button type='button'>Get your project started now</button>
                        </Link>
                        <Link className='limited-border no-border-button-shadow no-bg-hover slab5-project' to='why-video' smooth={true}>
                            <button type='button'>Let's find out more</button>
                        </Link>
                        <Link className='limited-border no-bg-hover slab5-project' to='why-video' smooth={true}>
                            <div className={'arrow-down-projects'}><CaretDownOutlined /></div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Projects;
