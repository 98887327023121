import * as React from 'react';
import ReactPlayer from 'react-player';
import {useEffect, useState} from "react";
import {isMobileOnly} from "react-device-detect";
const Player = ({url, muted}) => {
    const [updateUrl, setUpdateUrl] = useState('');
    const [isMuted, setMuted] = useState(false);
    const [isPlaying, setPlaying] = useState(true);
    useEffect(() => {
        setUpdateUrl(url.toString());
        }, [url]);
    useEffect(() => {
        setMuted(muted);
        setPlaying(!muted);
    }, [muted]);
    return (
        <>
            <ReactPlayer
                header={'Access-Control-Allow-Origin: *'}
                url={updateUrl}
                width='100vw'
                height='100vh'
                playing={isPlaying}
                controls={true}
                muted={isMuted}
                playsinline={true}
                pip={false}
                loop={true}
                onError={e => console.log('ReactPlayer: ', e)}
                sandbox={"allow-scripts allow-same-origin allow-presentation"}
                config={{
                    iframe: {
                        iframeParams: {
                            disablePictureInPicture: 1,
                            fullscreen: isMobileOnly ? 1 : 0,
                            allowFullScreen: isMobileOnly ? 1 : 0,
                            pip: 0,
                            fs: isMobileOnly ? 1 : 0,
                            enablejsapi: 1,
                            playsinline: 1,
                            muted: 0,
                            cc_load_policy: 0,
                            sandbox: "allow-scripts allow-same-origin allow-presentation",
                        }
                    },
                    youtube: {
                        playerVars: {
                            fs: isMobileOnly ? 1 : 0,
                            enablejsapi: 1,
                            modestbranding: 1,
                            playsinline: 1,
                            loop: 1,
                            sandbox: "allow-scripts allow-same-origin allow-presentation",
                        }
                    },
                    vimeo: {
                        playerOptions: {
                            enablejsapi: 1,
                            fullscreen: 0,
                            allowFullScreen: 0,
                            pip: 0,
                            fs: 0,
                            playsinline: 1,
                        }
                    },
                    file: {
                        playerVars: {
                            enablejsapi: 1,
                            playsinline: 1,
                            fullscreen: 0,
                            allowFullScreen: isMobileOnly ? 1 : 0,
                            pip: 0,
                            fs: 0,
                            disablePictureInPicture: 1,
                        }
                    },
                }}
            />
        </>
    );
}
export default Player;
