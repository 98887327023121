import * as React from 'react';
import {Route, Routes} from "react-router-dom";
import {PreLoader} from "./components/index"
import {LandingPage, NotFoundPage} from "./routes/index";
import {Layout} from 'antd';
import ScrollToTop from "./utils/ScrollToTop";

function App() {
    return (
        <div className="App">
            <PreLoader whichState={false} />
            <ScrollToTop />
            <Layout>
                <div className="Main">
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </div>
            </Layout>
        </div>
    );
}

export default App;
