import * as React from 'react';
import VIDEO_POSTER from '../media/images/video_poster_1.jpg';
import MAIN_VIDEO_1 from '../media/video/New_test.mp4';
import MAIN_VIDEO_1_HQ from '../media/video/New_test.mp4';
import {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import {Spinner} from "./index";
import {isMobile} from "react-device-detect";
const Hero = ({videoState, OverrideState}) => {
    const [isPreloading, setIsPreloading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(true);
    const heroVideoRef = useRef();
    const errorVideo = (e)=>{
        console.log(e + 'VIDEO ERROR!');
    }
    const Variants = {
        offscreen: {
            scale: 2,
        },
        onscreen: {
            scale: 1,
            transition: {
                ease: "easeQuadOut",
                type: "spring",
                bounce: 0.1,
                delay: 2,
                duration: 5,
            }
        }
    };
    useEffect(() => {
        if(heroVideoRef.current?.onLoadedData || heroVideoRef.current?.onWaiting){
            setIsPreloading(true);
            console.log('HERO VIDEO: LOADING');
        } else {
            setIsPreloading(false);
            console.log('HERO VIDEO: LOADED');
        }
    }, [isPreloading]);
    useEffect(() => {
        if (OverrideState) {
            heroVideoRef.current?.pause();
        } else {
            if (!isPlaying) {
                heroVideoRef.current?.pause();
                console.log('HERO VIDEO: PAUSED');
            } else {
                heroVideoRef.current?.play();
            }
        }
    }, [isPlaying, OverrideState]);
    useEffect(() => {
        if (videoState) {
            setIsPlaying(true);
        } else if (!videoState) {
            setIsPlaying(false);
        }
    }, [videoState]);
    return (
        <>
            <div className='section1'>
                <div className='section1Video'>
                    {isMobile ?
                        <>
                            <video
                                width={1920}
                                height={1080}
                                src={MAIN_VIDEO_1}
                                muted={true}
                                playsInline={true}
                                ref={heroVideoRef}
                                autoPlay={true}
                                loop={true}
                                controls={false}
                                preload="metadata"
                                onError={errorVideo}
                                poster={VIDEO_POSTER}
                            />
                        </>
                        :
                        <>
                            <motion.video
                                width={1920}
                                height={1080}
                                src={MAIN_VIDEO_1_HQ}
                                muted={true}
                                playsInline={true}
                                ref={heroVideoRef}
                                autoPlay={true}
                                loop={true}
                                controls={false}
                                preload="metadata"
                                onError={errorVideo}
                                initial={Variants.offscreen}
                                whileInView={Variants.onscreen}
                                viewport={{ once: true, amount: 0.15 }}
                            />
                        </>
                    }
                    {isPreloading ? <div className='spinner'><Spinner /></div> : null}
                </div>
            </div>
        </>
    )
}
export default Hero;

