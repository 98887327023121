import * as React from 'react';
import {
    ArrowRightOutlined,
    CaretDownOutlined,
    InstagramOutlined,
    PhoneOutlined,
    WhatsAppOutlined,
    MailOutlined,
    CalendarOutlined, FireOutlined
} from "@ant-design/icons";
import {CALENDLY, EMAIL, INSTAGRAM, PHONE_NUMBER, SCORECARD, TEL_NUMBER, WHATSAPP} from "../constants/polarisData";
import loadingLogo from "../media/images/main_logo_svg.svg";
import qrCode from '../media/qr_codes/qrcode_phone.png';
import {Link} from 'react-scroll';
import {useEffect} from "react";
import {Parallax} from "react-scroll-parallax";
import {isMobile} from "react-device-detect";
const ContactUs = () => {
    const [valueName, setValueName] = React.useState('');
    const [valueEmail, setValueEmail] = React.useState('');
    const [flag, setFlag] = React.useState(false);
    const handleSubmit = (e) => {
        console.log("Form Submitted: " + e);
    };
    const handleValidation = (e) => {
        setValueName(e.target.value);
        setValueEmail(e.target.value);
        console.log('value: ' + valueName);
        console.log('value: ' + valueEmail);
    };
    useEffect(() => {
        if(valueName === undefined || valueEmail === undefined){
            setFlag(false);
        }
        if (valueName === '' || valueEmail === '') {
            setFlag(false);
        } else if(valueEmail.includes('@') && valueEmail.includes('.')){
            setFlag(true);
        } else {
            setFlag(false);
        }
    }, [valueName, valueEmail]);
    return(
        <>
            <div className='section-contact-us bg-blur' id='contact-us'>
                <Parallax disabled={isMobile} speed={isMobile ? 0 : 34} className="container-fluid remove">
                    <p className="text">CONTACT US</p>
                </Parallax>
                <div className={'form-container'}>
                    <div className={'row-form'}>
                        <img src={loadingLogo} alt='logo'/>
                        <h3 aria-label={'LONDON, SURREY, WORLDWIDE'}>LONDON | SURREY | WORLDWIDE</h3>
                    </div>
                    <h3><b>Thank you</b> for your interest in working with us at <font
                        className={'polaris-text'}>POLARIS</font>
                        <sub className={'subscript-ext'}>&reg;</sub> <font className={'polaris-text-ext'}>STUDIO</font>
                        , let's start <b>transforming</b> your <b>vision</b> into your <b>reality</b>.<br/><br/>
                        <b>Contact us now</b> for a complimentary consultation to discuss your project in detail.
                    </h3>
                    <div className={'row-form'}>
                        <a className={'form-button'}
                           href={CALENDLY}
                           target="_blank"
                           rel="noopener noreferrer">
                            <button className={'calendly-button'} type={"button"}><p><CalendarOutlined/> Book Via
                                Calendly</p>
                            </button>
                        </a>
                    </div>
                    <div className={'row-form'}>
                        <a className={'form-button'}
                           href={TEL_NUMBER}
                           rel="noopener noreferrer">
                            <button className={'call-button'} type={"button"}><p><PhoneOutlined/> {PHONE_NUMBER}</p>
                            </button>
                        </a>
                    </div>
                    <h3 className={'padding-from-alt'}><i className={'office-hours'}>Our office hours are <br/>Monday
                        to Friday from 9AM - 7PM</i></h3>
                    <h3><b>Looking for Dental Video Marketing?</b> Please click below and find out if your dental
                        practice can benefit from the power of <font
                            className={'polaris-text'}>POLARIS</font>
                        <sub className={'subscript-ext'}>&reg;</sub> video marketing:
                    </h3>
                    <div className={'row-form'}>
                        <a className={'form-button'}
                           href={SCORECARD}
                           target="_blank"
                           rel="noopener noreferrer">
                            <button className={'scorecard-button'} type={"button"}><p><FireOutlined /> Discover Now</p>
                            </button>
                        </a>
                    </div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <h3><b>Connect and chat with us via our other contact options below:</b></h3>
                    <h3 className={'contact-sub-text'}>SCAN TO CALL</h3>
                    <div className={'qr-code'}><img src={qrCode} alt={'QR Code to phone'}/></div>
                    <h3 className={'contact-sub-text'}>MORE OPTIONS BELOW</h3>
                    <div className={'row-form'}>
                        <a className={'form-button'}
                           aria-label="Chat on WhatsApp" href={WHATSAPP} rel="noopener noreferrer"
                           target="_blank">
                            <button className={'whatsapp-button'} type={"button"}><p><WhatsAppOutlined/> Chat On
                                WhatsApp</p></button>
                        </a>
                    </div>
                    <div className={'row-form'}>
                        <a className={'form-button'}
                           aria-label="Chat on Instagram" href={INSTAGRAM} rel="noopener noreferrer"
                           target="_blank">
                            <button className={'instagram-button'} type={"button"}><p><InstagramOutlined/> Direct
                                Message Us</p></button>
                        </a>
                    </div>
                    <div className={'row-form'}>
                        <a className={'form-button'}
                           aria-label="Email Us Directly" href={EMAIL} rel="noopener noreferrer"
                           target="_blank">
                            <button className={'email-button'} type={"button"}><p><MailOutlined/> Email Our Team</p>
                            </button>
                        </a>
                    </div>
                    <h2>If you prefer, we have an online form for you to submit below:</h2>
                    <Link to={'online-form'} smooth={'easeInOutQuad'}>
                        <h3 className={'down-arrow-accent-form'} id={'online-form'}><i><CaretDownOutlined/></i></h3>
                    </Link>
                    <div className={'contact-form'}>
                        <form className={'contact-form'} name={"contact"} onSubmit={handleSubmit} method={'post'}
                              data-netlify={"true"} data-netlify-honeypot={"bot-field"}>
                            <input type="hidden" name="form-name" value="contact"/>
                            <label>First off, what's your name?</label>
                            <input autoComplete="name" onChange={handleValidation} type="text" name="name"
                                   placeholder={'Full Name (required)'} required/>
                            <label>What's your email address?</label>
                            <input autoComplete="email" onChange={handleValidation} type="email" name="email"
                                   placeholder={'Email (required)'} required/>
                            <label>Enter your phone number</label>
                            <input autoComplete="tel" type="tel" name="phone" placeholder={'Phone Number (optional)'}/>
                            <label form={'time-selection'}>Prefer a time of day for us to contact you?
                                <select name="time preference" defaultValue={'anytime'} id={'time-selection'}
                                        placeholder={'(optional)'}>
                                    <option value="Anytime">Anytime of day</option>
                                    <option value="Morning">Morning</option>
                                    <option value="Noon">Noon</option>
                                    <option value="Evening">Evening</option>
                                </select>
                            </label>
                            <label>Have an estimated budget?</label>
                            <input type="text" name="budget" placeholder={'Enter your budget (optional)'}/>
                            <label>Anything else we should know?</label>
                            <textarea name="comment" placeholder={'Comment anything you like (optional)'}></textarea>
                            <label className={'submit-policy-text'}>
                                <i>
                                    By submitting this form, you agree to our
                                    <a href="https://privacy-policy-2dbcf1.netlify.app/" rel="noopener noreferrer"
                                       target="_blank"><b> Privacy Policy</b></a>, which is how we protect and manage
                                    your data via this enquiry and our websites.
                                </i>
                            </label>
                            <button type="submit"
                                    className={flag ? 'submit-form-button valid-button-border' : 'submit-form-button submit-form-button-disabled-transform invalid-button-border'}
                                    onClick={handleSubmit}><p style={{opacity: flag ? '1' : '0.3'}}>Send&nbsp;</p>
                                <i>{flag ? <ArrowRightOutlined/> : ''}</i></button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ContactUs;
