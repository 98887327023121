import * as React from 'react';

export function NotFoundPage() {
    const styles = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            color: '#fff',
    }
    return (
        <>
            <div className="container-fluid-sticky">
                <p className="text-sticky">ERROR 404</p>
            </div>
            <div style={styles}>
                <a href={'/'} className='limited-border no-bg-hover slab5-project'>
                    <button type='button'>ERROR 404: PAGE DOESN'T EXIST</button>
                </a>
                <a href={'/'} className='slab5-project'>
                    <button type='button'>Take me home!</button>
                </a>
                <a href={'/'} className='limited-border no-bg-hover slab5-project'>
                    <button type='button'>BACK TO POLARIS STUDIO</button>
                </a>
            </div>
        </>
    )
}

export default NotFoundPage;
