import * as React from 'react';
import MAIN_VIDEO_2 from '../media/video/test_3.mp4';
import MAIN_VIDEO_2_HQ from '../media/video/test_3_HQ.mp4';
import {useInView} from "react-intersection-observer";
import {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import {Spinner, TrustedBy} from "./index";
import logo from '../media/images/main_logo_long.svg';
import {Link} from 'react-scroll';
import {CaretDownOutlined} from "@ant-design/icons";
import {Parallax} from "react-scroll-parallax";
import {isMobile} from "react-device-detect";

const VideoSection = () => {
    const options = {
        threshold: 0.2,
    }
    const { ref, inView } = new useInView(options);
    const videoRef = useRef();
    const Variants = {
        offscreen: {
            y: 0,
            x: 0,
            scale: 1.5,
        },
        onscreen: {
            y: 0,
            x: 0,
            scale: 0.82,
            transition: {
                ease: "easeQuadOut",
                type: "spring",
                bounce: 0.1,
                delay: 0.1,
                duration: 3,
            }
        }
    };
    const VariantsReversed = {
        offscreen: {
            y: 0,
            x: 0,
            scale: 0.75,
        },
        onscreen: {
            y: 0,
            x: 0,
            scale: 1,
            transition: {
                ease: "easeQuadOut",
                type: "spring",
                bounce: 0.1,
                delay: 0.1,
                duration: 3,
            }
        }
    };
    const refreshPage = (e)=>{
        console.log('REFRESHING PAGE' + e);
    }
    const playVideo = () => { videoRef.current?.play(); }
    const pauseVideo = () => { videoRef.current?.pause(); }
    const [isPreloading, setIsPreloading] = useState(true);
    useEffect(() => {
        if (inView) {
            playVideo();
        }
        else {
            pauseVideo();
        }
        if(inView && videoRef.current?.paused) {
            playVideo();
        }
    }, [inView]);
    useEffect(() => {
        if(isPreloading) {
            console.log('BTS VIDEO: LOADING');
        } else {
            console.log('BTS VIDEO: LOADED');
        }
    }, [isPreloading]);
    useEffect(() => {
        if(videoRef.current?.onLoadStart) {
            setIsPreloading(true);
        } else {
            setIsPreloading(false);
        }
    }, []);
    return (
        <>
            <div className='videoSection border-radius-video' ref={ref}>
                {isMobile ?
                    <>
                        <motion.video
                            width={1920}
                            height={1080}
                            src={MAIN_VIDEO_2}
                            muted={true}
                            playsInline={true}
                            ref={videoRef}
                            autoPlay={true}
                            loop={true}
                            controls={false}
                            preload="metadata"
                            onError={(e) => refreshPage(e)}
                            initial={VariantsReversed.offscreen}
                            whileInView={VariantsReversed.onscreen}
                            viewport={{ once: true, amount: 0.15 }}
                        />
                    </>
                    :
                    <>
                        <motion.video
                            width={1920}
                            height={1080}
                            src={MAIN_VIDEO_2_HQ}
                            muted={true}
                            playsInline={true}
                            ref={videoRef}
                            autoPlay={true}
                            loop={true}
                            controls={false}
                            preload="metadata"
                            onError={(e) => refreshPage(e)}
                            initial={Variants.offscreen}
                            whileInView={Variants.onscreen}
                            viewport={{ once: true, amount: 0.15 }}
                        />
                    </>
                }
                {isPreloading ? <div className='spinner'><Spinner /></div> : null}
                <Parallax className="container-fluid-sticky" disabled={isMobile} speed={isMobile ? 0 : 50}>
                    <p className="text-sticky"><img className="text-sticky" src={logo} alt={'logo'} /></p>
                </Parallax>
                <div className={'middle-text-pos-container'}>
                    <div className={'middle-text-pos'}>
                        <h1>IMPACT DRIVEN STORIES</h1>
                        <p>We create engaging and compelling content that captivates audiences, inspires action and drives meaningful results</p>
                    </div>
                    <TrustedBy heading_text={'TRUSTED BY'}/>
                    <Link className='slab5-project slab5-project-why' to='contact-us' smooth={false}>
                        <button type='button'>Let's get started together</button>
                    </Link>
                    <Link className={isMobile ? 'limited-border no-bg-hover slab5-project' : 'limited-border no-bg-hover slab5-project opacity-0'} to='projects' smooth={'easeInOutQuad'}>
                        <div ref={ref} className={'arrow-down-projects'}><CaretDownOutlined /></div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default VideoSection;
