import * as React from 'react';
import logoDJI from '../media/images/company-logos/dji.png';
import logoRED from '../media/images/company-logos/red.png';
import logoARRI from '../media/images/company-logos/arri.png';
import logoSONY from '../media/images/company-logos/sony.png';
const PoweredBy = ({heading_text}) => {
    return (
        <>
            <div className={'section-powered-by'}>
                <div className='section-trusted-by-container'>
                    <h3>{heading_text ? heading_text : 'N/A'}</h3>
                    <div>
                        <div className={'row-logos'}>
                            <img className={'powered-by-logo'} src={logoARRI} alt={'logo'}/>
                            <img className={'powered-by-logo'} src={logoRED} alt={'logo'}/>
                            <img className={'powered-by-logo scale-down-logo'} src={logoDJI} alt={'logo'}/>
                            <img className={'powered-by-logo scale-up-logo'} src={logoSONY} alt={'logo'}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PoweredBy;
