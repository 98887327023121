import * as React from 'react';
import {useEffect, useState} from "react";
import { Squash as Hamburger } from 'hamburger-react'
import {
    CalendarOutlined,
    MenuOutlined,
    PhoneOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';
import Link from 'react-scroll/modules/components/Link';
import {
    MENU_ITEM_2,
    MENU_ITEM_3,
    MENU_ITEM_5,
    TEL_NUMBER,
    WHATSAPP,
    MENU_ITEM_6,
    MENU_ITEM_7,
    MENU_ITEM_8,
    MENU_ITEM_1_REF,
    MENU_ITEM_5_REF,
    MENU_ITEM_8_REF,
    MENU_ITEM_2_REF,
    MENU_ITEM_3_REF, MENU_ITEM_7_REF, MENU_ITEM_6_REF, CALENDLY
} from "../../constants/polarisData";
import loadingLogo from "../../media/images/main_logo_svg.svg";
const NavMenu = ({scrollDirection}) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [clicked, setClicked] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (screenSize >= 921) {
            setActiveMenu(false);
            setOpen(false);
        }
        if (screenSize <= 921) {
            setActiveMenu(false);
            setOpen(false);
        }
    }, [screenSize]);
    useEffect(() => {
        if (scrollDirection === "down" || scrollDirection === "up") {
            setOpen(false);
            setActiveMenu(false);
        }
    }, [scrollDirection]);
    useEffect(() => {
        if (clicked) {
            document.body.querySelector('.section1Video').classList.add('darken-video-hero');
        }
    }, [clicked]);
    function closeMenu(){
        if (screenSize <= 921 && isOpen){
            setOpen(false);
            setActiveMenu(false);
            setClicked(true);
        }
    }
    function menuState(){
        setActiveMenu(!activeMenu);
    }
    return (
        <>
            <div>
                <div className="menu-control-container hover-link" aria-label="menu button" aria-expanded="false" role="button">
                    <Hamburger
                        aria-expanded="false" role="button" tabindex="0"
                        aria-label="Menu button"
                        rounded
                        color={"rgb(252,252,252)"}
                        easing={"ease-in"}
                        toggled={isOpen}
                        toggle={setOpen}
                        onToggle={menuState}>
                        <MenuOutlined className="ant-icon-menu-button" aria-expanded="false" role="button" tabindex="0"/>
                    </Hamburger>
                </div>
                { activeMenu && (
                    <div className='nav-menu' aria-label="open menu" aria-expanded="true">
                        <Link onClick={closeMenu} to={MENU_ITEM_1_REF} spy={true} smooth={false} className='menu-logo-small'>
                            <img src={loadingLogo} alt='logo'/>
                            <h3 aria-label={'LONDON, SURREY, WORLDWIDE'}>LONDON | SURREY | WORLDWIDE</h3>
                        </Link>
                        <Link onClick={closeMenu} to={MENU_ITEM_2_REF} spy={true} smooth={false}>
                            <div className='menu-text-button' aria-label="Who we are"><h1>{MENU_ITEM_2}</h1></div>
                        </Link>
                        <Link onClick={closeMenu} to={MENU_ITEM_3_REF} spy={true} smooth={false}>
                            <div className='menu-text-button' aria-label="Portfolio"><h1>{MENU_ITEM_3}</h1></div>
                        </Link>
                        <Link onClick={closeMenu} to={MENU_ITEM_7_REF} spy={true} smooth={false}>
                            <div className='menu-text-button' aria-label="Why Video"><h1>{MENU_ITEM_7}</h1></div>
                        </Link>
                        <Link onClick={closeMenu} to={MENU_ITEM_6_REF} spy={true} smooth={false}>
                            <div className='menu-text-button' aria-label="Formats"><h1>{MENU_ITEM_6}</h1></div>
                        </Link>
                        <Link onClick={closeMenu} to={MENU_ITEM_8_REF} spy={true} smooth={false}>
                            <div className='menu-text-button' aria-label="Why Us"><h1>{MENU_ITEM_8}</h1></div>
                        </Link>
                        <Link onClick={closeMenu} to={MENU_ITEM_5_REF} spy={true} smooth={false}>
                            <div className='menu-text-button' aria-label="Get in touch"><h1>{MENU_ITEM_5}</h1></div>
                        </Link>
                        <div className={'row-form-small'}>
                            <a className={'form-button-small'}
                               aria-label="Call Us Now"
                               href={TEL_NUMBER}
                               rel="noopener noreferrer">
                                <button className={'call-button-small'} type={"button"}><p><PhoneOutlined /></p></button>
                            </a>
                            <a className={'form-button-small'}
                               aria-label="Chat on WhatsApp"
                               href={WHATSAPP}
                               rel="noopener noreferrer"
                               target="_blank">
                                <button className={'whatsapp-button-small'} type={"button"}><p><WhatsAppOutlined/></p></button>
                            </a>
                            <a className={'form-button-small'}
                               aria-label="Book Consultation Via Calendly"
                               href={CALENDLY}
                               rel="noopener noreferrer"
                               target="_blank">
                                <button className={'contacts-button-small'} type={"button"}><p><CalendarOutlined /></p></button>
                            </a>
                        </div>
                        <div className={'spacer-menu-bottom'}> </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default NavMenu;
