import * as React from "react";
import {Spinner} from "react-activity";
import 'react-activity/dist/Spinner.css';

export function SpinnerComponent() {
    return (
        <>
            <Spinner size={55} color="#fff" speed={1.5} animating={true}/>
        </>
    )
}

export default SpinnerComponent;
