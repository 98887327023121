import * as React from 'react';
import { Hero, Spinner, Slabs, ContactUs, PoweredBy, ReviewSection, ContactButton, NavBar, OtherService } from "../components/index";
import {Fade} from "react-awesome-reveal";
import Lottie from "lottie-react";
import {useInView} from "react-intersection-observer";
import {useEffect, useState} from "react";
import scroll from "../media/accents/scroll_down_3.json";
import {Link} from "react-scroll";
import {ParallaxProvider} from "react-scroll-parallax";
import { useSpring, animated } from 'react-spring'
import {EMAIL, TEL_NUMBER, WHATSAPP} from "../constants/polarisData";
import {MailOutlined, PauseOutlined, PhoneOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {isMobile} from "react-device-detect";
import {IoPlay} from "react-icons/io5";
const VideoSection = React.lazy(() => import('../components/index').then(module => ({default: module.VideoSection})));
const Projects = React.lazy(() => import('../components/index').then(module => ({default: module.Projects})));
const WhyVideo = React.lazy(() => import('../components/index').then(module => ({default: module.WhyProduct})));
const QuoteSection = React.lazy(() => import('../components/index').then(module => ({default: module.QuoteSection})));
const WhyUs = React.lazy(() => import('../components/index').then(module => ({default: module.WhyUs})));
const FooterSection = React.lazy(() => import('../components/index').then(module => ({default: module.FooterSection})));
export function LandingPage(){
    const [runOnce, setRunOnce] = useState(true);
    const [isPlaying, setIsPlaying] = useState(true);
    const [overridePlayState, setOverridePlayState] = useState(false);
    const [clicked, setClicked] = useState(false);
    const refAnim1 = React.useRef();
    const options = {
        threshold: 0.2,
    }
    const options2 = {
        threshold: 0.6,
    }
    const options3 = {
        threshold: 0.2,
    }
    const options4 = {
        threshold: 0.2,
    }
    const options5 = {
        threshold: 0.2,
    }

    const [ref, inView ] = useInView(options);
    const [ref2, inView2] = useInView(options2);
    const [ref3, inView3] = useInView(options3);
    const [ref4, inView4] = useInView(options4);
    const [ref5, inView5] = useInView(options5);
    const viewProps = {
        refHero: inView,
        refProjectSection: inView3,
        refContactSection: inView4
    }
    const titleAnimation = useSpring({
        from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
        to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
        delay: 4000,
        config: { mass: 5, tension: 500, friction: 150, easing: 'easeInOutQuad' }
    })
    const subTitleAnimation = useSpring({
        from: { opacity: 1, color: 'white', transform: `translate3d(0px,50px,0px)` },
        to: { opacity: 0.6, color: 'white', transform: `translate3d(0px,0px,0px)` },
        delay: 5000,
        config: { mass: 5, tension: 500, friction: 300, easing: 'easeInOutQuad'}
    })
    const videoStateButton = () => {
        if (isPlaying) {
            setIsPlaying(false);
            setOverridePlayState(true);
        } else {
            setIsPlaying(true);
            setOverridePlayState(false);
        }
    }
    const onButtonClick = () => {
        setClicked(true);
    }
    useEffect(() => {
        if (clicked) {
            document.body.querySelector('.section1Video').classList.add('darken-video-hero');
        }
    }, [clicked]);
    useEffect(() => {
        if(inView){
            refAnim1.current?.play();
        }else if(!inView){
            refAnim1.current?.pause();
        }
    }, [inView]);
    useEffect(() => {
        if(inView2 && runOnce) {
            setRunOnce(false);
            document.body.querySelector('.section1Video').classList.add('darken-video-hero');
            setIsPlaying(false);
            console.log('PAUSED HERO  2');
        }
        else if(inView2 &&  !runOnce) {
            setIsPlaying(false);
            console.log('PAUSED HERO  2');
        } else {
            setIsPlaying(true);
            console.log('PLAYING HERO VIDEO 2');
        }
    }, [inView2, runOnce]);
    useEffect(() => {
        if(inView3){
            setIsPlaying(false);
            console.log('PAUSED HERO VIDEO 3');
        } else {
            setIsPlaying(true);
            console.log('PLAYING HERO VIDEO 3');
        }
    }, [inView3]);
    useEffect(() => {
        if(inView4){
            setIsPlaying(false);
        } else {
            setIsPlaying(true);
        }
    }, [inView4]);
    useEffect(() => {
        if(inView5){
            setIsPlaying(false);
        } else {
            setIsPlaying(true);
        }
    }, [inView5]);
    return (
        <>
            <header className="navbar">
                <NavBar props={viewProps}/>
            </header>
            <ContactButton props={viewProps}/>
            <ParallaxProvider>
                <section id='hero' ref={ref}>
                    <Hero videoState={isPlaying} OverrideState={overridePlayState}/>
                </section>
                <section id='slabs'>
                    <div className={'hero-title-main'}>
                        <div className='spacer-hero-title'>
                            <div className={'hero-title-main override-title'}>
                                <animated.b style={titleAnimation} className={'text-white-boldest'}>
                                    TRANSFORM
                                    <animated.b style={subTitleAnimation}> YOUR VISION<br/>INTO </animated.b>
                                </animated.b>
                                <b className={'text-overlay-bg-video'}>
                                    <Fade triggerOnce={true} cascade={true} delay={5500} duration={300}>REALITY</Fade>
                                </b>
                            </div>
                        </div>
                        <Link to={'slabs'} spy={false} smooth={'easeInOutQuad'}>
                        <h3 className={'no-decoration'}>
                            <font className={'polaris-text-2'}>POLARIS</font>
                            <sub className={'subscript-ext-2'}>&reg;</sub>
                            <br/><font className={'sub-hero-logo-text'}>PROFESSIONAL VIDEO PRODUCTION</font>
                        </h3></Link>
                        <Fade easing={'easeInOutQuad'} triggerOnce={true} cascade={true} damping={0.8} duration={1000} delay={7000}>
                            <Link className='override-title slab5-project slab5-project-why cta-main-color' isDynamic={true} to="contact-us" smooth={false}>
                                <button onClick={onButtonClick} type='button'>BOOK A CONSULTATION NOW</button>
                            </Link>
                            <h3>SCHEDULE YOUR FREE CONSULTATION WITH A <br/>VIDEO PRODUCTION EXPERT TODAY</h3>
                        </Fade>
                        <div className={'hero-badges'}>
                            <a href={TEL_NUMBER} rel="noopener noreferrer"><PhoneOutlined /></a>
                            <a aria-label="Chat on WhatsApp" href={WHATSAPP} rel="noopener noreferrer"
                               target="_blank"><WhatsAppOutlined /></a>
                            <a aria-label="Email Us Directly" href={EMAIL} rel="noopener noreferrer"
                               target="_blank"><MailOutlined /></a>
                        </div>
                        { isMobile ?
                            <>
                                <Fade easing={'easeInOutQuad'} triggerOnce={true}  duration={500} delay={20000}>
                                    <Link to={'slabs'} spy={false} smooth={'easeInOutQuad'}>
                                        <Lottie className='scroll-down-icon-main-2' lottieRef={refAnim1} animationData={scroll} autoplay={false} loop={true} />
                                    </Link>
                                </Fade>
                            </>
                        :
                            null
                        }
                        <button
                            className={'hero-video-state-button'}
                            onClick={videoStateButton}
                            aria-label={'Play/Pause Video'}>
                            {isPlaying && !overridePlayState ? <PauseOutlined /> : <IoPlay />}
                        </button>
                    </div>
                    <Slabs />
                </section>
                <section id='video-section' className='min-section' ref={ref2}>
                    <React.Suspense fallback={<Spinner />}><VideoSection /></React.Suspense>
                </section>
                <section className='section-powered-by' id='driven-stories'>
                    <PoweredBy heading_text={'POWERED BY'} />
                </section>
                <section id='projects' ref={ref3}>
                    <React.Suspense fallback={<Spinner />}><Projects /></React.Suspense>
                </section>
                <section id='why-video' >
                    <React.Suspense fallback={<Spinner />}><WhyVideo /></React.Suspense>
                </section>
                <section id='video-format'>
                    <OtherService />
                </section>
                <section className='section-quote' ref={ref5}>
                    <React.Suspense fallback={<Spinner />}><QuoteSection /></React.Suspense>
                </section>
                <section id='why-us'>
                    <React.Suspense fallback={<Spinner />}><WhyUs /></React.Suspense>
                </section >
                <section className='section-reviews' id='reviews'>
                    <ReviewSection />
                </section>
                <section ref={ref4}>
                    <ContactUs />
                </section>
                <section>
                    <React.Suspense fallback={<Spinner />}><FooterSection /></React.Suspense>
                </section>
            </ParallaxProvider>
        </>
    );
}
export default LandingPage;
