import * as React from 'react';
import {useEffect, useState} from "react";
import {
    BlockOutlined,
    BulbOutlined,
    CaretDownOutlined, CompassOutlined, ControlOutlined, DoubleRightOutlined,
    EnvironmentOutlined, EyeOutlined, FileDoneOutlined,
    NotificationOutlined, SelectOutlined,InstagramOutlined,
    ShopOutlined,
    SketchOutlined, ThunderboltOutlined, VideoCameraAddOutlined,
    VideoCameraOutlined,
    YoutubeOutlined,
} from "@ant-design/icons";
import {Collapse} from "react-collapse";
import {Link} from "react-scroll";
import {Parallax} from "react-scroll-parallax";
import {isMobile} from "react-device-detect";
import {BsMeta, BsTiktok} from "react-icons/bs";
import {MdPodcasts} from "react-icons/md";
const OtherServices = () => {
    const [isPage, setPage] = useState(true);
    const [isOpened, setIsOpened] = useState(!isMobile);
    const toggle = () => setIsOpened(!isOpened);
    const togglePage1 = () => setPage(true);
    const togglePage2 = () => setPage(false);

    useEffect(() => {
        if(isOpened) {
            console.log('OPENED: Formats Menu');
        } else {
            console.log('CLOSED: Formats Menu');
        }
    }, [isOpened]);
    useEffect(() => {
        if(isPage) {
            console.log('OPENED: Page 1');
        } else {
            console.log('CLOSED: Page 2');
        }
    }, [isPage]);
    return(
        <>
            <div className='section-why'>
                <Parallax disabled={isMobile} speed={isMobile ? 0 : 34} className="container-fluid">
                    <p className="text">VIDEO {isPage ? 'FORMATS' : 'SERVICES'}</p>
                </Parallax>
                <div className='button-service-row'>
                    <div className={ isPage ? 'button-no-fill border-highlighted' : 'button-no-fill'}>
                        <button onClick={togglePage1}>FORMATS</button>
                    </div>
                    <div className={!isPage ? 'button-no-fill border-highlighted' : 'button-no-fill'}>
                        <button onClick={togglePage2}>SERVICES</button>
                    </div>
                </div>
                <h3>Choose from a range of services and formats, partner with our industry experts to bring your vision to life and achieve measurable results. <br/><br/>Our expertise lies in creating engaging solutions, offered both as a package or separate services, all aimed at captivating your audience.</h3>
                <div className='services-container'>
                    <div className="row">
                        {isPage ?
                            <>
                                <div className="column" onClick={toggle}>
                                    <i><SketchOutlined /></i>
                                    <h2>Brand Films</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>We craft visually bold brand films that showcase your business's values and perspective, inspiring action. Let us help you stand out and reach your goals.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><VideoCameraOutlined /></i>
                                    <h2>Documentaries</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Inspiring documentaries showcase your expertise, values, and achievements, engaging clients and strengthening brand connection. We've got you covered.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><EnvironmentOutlined /></i>
                                    <h2>Live Events</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Capture and document your corporate events and live demonstrations with our expert filming and live streaming services.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><ShopOutlined /></i>
                                    <h2>Commercials</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Captivate and drive sales with informative content that showcases your products as the clear choice for customers. Let's help you create compelling content that converts.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><NotificationOutlined /></i>
                                    <h2>Educational</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Educational video content is a powerful tool for reaching wider audiences, simplifying complex concepts, and achieving goals. Enhance yur learning experience with Polaris.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><YoutubeOutlined /></i>
                                    <h2>Social Media Platforms</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Level up your social media game with our social media approach which blends storytelling, striking visuals, and sound to deliver a clear and compelling call to action.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><InstagramOutlined /></i>
                                    <h2>Instagram</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>A highly visual platform that demands visually stunning content. With our video production services, we can help you create videos that showcase your brand and stand out in the crowded Instagram feed.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><BsMeta /></i>
                                    <h2>Meta</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Meta, formerly known as Facebook, is a highly visual platform that prioritizes quality content. With our video production expertise, we can help you create eye-catching videos that showcase your brand and stand out from the crowd.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><BsTiktok /></i>
                                    <h2>TikTok</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>A highly creative platform that demands bold and visually stunning content. With our video production expertise, we can help you create videos that capture the attention of TikTok's highly engaged audience.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><MdPodcasts /></i>
                                    <h2>Podcasts</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Engage your audience with a high-quality podcast production. Our experienced team will create a captivating visual and listening experience that delivers your message effectively. From concept to distribution.</p>
                                    </Collapse>
                                </div>
                            </>
                            :
                            <>
                                <div className="column" onClick={toggle}>
                                    <i><BulbOutlined /></i>
                                    <h2>Ideation</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Every media project begins with an idea, that creative hook that makes your video engaging and memorable. We ideate campaigns for your business using the best data driven research and tactics.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><DoubleRightOutlined /></i>
                                    <h2>Strategy</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Once we have an idea, we need to figure out how to make it work and we dive deeper into the complexity of that idea. We'll figure out the best strategy for your campaign.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><FileDoneOutlined /></i>
                                    <h2>Scripting</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>We can script your video project, either collaboratively from your own material and input, or simply based on our own research and your feedback.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><BlockOutlined /></i>
                                    <h2>Storyboards</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>For complex or conceptual video projects, we produce storyboards so you can visualise your final film before we hit record.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><EyeOutlined /></i>
                                    <h2>Pre-Visualisation</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Our advanced software technologies create immersive 3D virtual walkthrough of the proposed plans, helping you understand what to expect from the shoot and make necessary changes before filming.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><CompassOutlined /></i>
                                    <h2>Logistics</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Our pre-production team will organise everything from the creative requirements such as locations, cast, wardrobe and props to the really important stuff, like lunch.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><ThunderboltOutlined /></i>
                                    <h2>Lighting</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Lighting is at the heart of our visual style, creating depth, texture and drawing the eye to the key component of the scene.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><VideoCameraAddOutlined /></i>
                                    <h2>Filming</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Your project will be filmed on the latest, cinema-standard camera equipment, professionally supported by a variety of grip gear & lighting.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><SelectOutlined /></i>
                                    <h2>Editing</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>There’s a lot that goes into the editing of your video. First, our editors cut the most effective version of your story or explainer, then there’s audio-editing, music and SFX.</p>
                                    </Collapse>
                                </div>
                                <div className="column" onClick={toggle}>
                                    <i><ControlOutlined /></i>
                                    <h2>Colour Grading</h2>
                                    {isOpened ? null : <h4>Learn More <CaretDownOutlined /></h4> }
                                    <Collapse isOpened={isOpened}>
                                        <p>Video content can tell a story through its grade alone. We colour and grade your media to reflect a tone, a feeling, an expression and or a level of professionalism.</p>
                                    </Collapse>
                                </div>
                            </>                        }
                    </div>
                </div>
                <Link to='contact-us' smooth={'easeInOutQuad'}>
                    <div className='slab5-project slab5-project-why'>
                        <button type='button' className='slab5-project'>Get in touch with us now</button>
                    </div>
                </Link>
                <Link to='why-us' className='limited-border no-border-button-shadow no-bg-hover slab5-project' smooth={'easeInOutQuad'}>
                    <button type='button'>Why Polaris Studio?</button>
                </Link>
                <Link className='limited-border no-bg-hover slab5-project' to='section-quote' smooth={'easeInOutQuad'}>
                    <div className={'arrow-down-projects'}><CaretDownOutlined /></div>
                </Link>
            </div>
        </>
    );
};
export default OtherServices;
