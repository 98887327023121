import * as React from 'react';
import {Link} from 'react-scroll';
import {animate, motion} from "framer-motion";
import slab_1 from '../media/images/slabs/slab_1_alt.webp';
import slab_1_FALLBACK from '../media/images/slabs/slab_1_alt.png';
import slab_2 from '../media/images/slabs/slab_2.webp';
import slab_2_FALLBACK from '../media/images/slabs/slab_2.png';
import slab_3 from '../media/images/slabs/slab_3.webp';
import slab_3_FALLBACK from '../media/images/slabs/slab_3.png';
import {Parallax} from "react-scroll-parallax";
import {isMobile} from "react-device-detect";
const Slabs = () => {
    const Variants = {
        offscreen: {
            y: 100,
            scale: 1.3,
        },
        onscreen: {
            y: 0,
            scale: 1,
            transition: {
                ease: "ease",
                type: "spring",
                bounce: 0.4,
                duration: 0.2,
                delayChildren: 0.1,
                staggerDirection: -1
            }
        }
    };
    const slabTitle_1 = 'We Strategize';
    const slabTitle_2 = 'We Direct';
    const slabTitle_3 = 'We Create';
    return (
        <>
            <div className='section2' id='section2'>
                <Parallax disabled={isMobile} speed={isMobile ? 0 : 55}>
                    <Link to={'video-section'} spy={true} smooth={'easeInOutQuad'}>
                        <div className='slab-container'>
                            <motion.div
                                className='slab1'
                                initial={Variants.offscreen}
                                whileInView={Variants.onscreen}
                                viewport={{ once: false, amount: 0.15 }}
                                onClick={() => animate(".slab1", {scale: .8})}
                            >
                                <picture>
                                    <source srcSet={slab_1} type="image/webp" />
                                    <img src={slab_1_FALLBACK} alt='We Strategize'/>
                                </picture>
                                <h2>{slabTitle_1}</h2>
                            </motion.div>
                            <motion.div
                                className='slab1'
                                initial={Variants.offscreen}
                                whileInView={Variants.onscreen}
                                viewport={{ once: false, amount: 0.15 }}
                                onClick={() => animate(".slab1", {scale: .8})}
                            >
                                <picture>
                                    <source srcSet={slab_2} type="image/webp" />
                                    <img src={slab_2_FALLBACK} alt='We Direct'/>
                                </picture>
                                <h2>{slabTitle_2}</h2>
                            </motion.div>
                            <motion.div
                                className='slab1'
                                initial={Variants.offscreen}
                                whileInView={Variants.onscreen}
                                viewport={{ once: false, amount: 0.15 }}
                                onClick={() => animate(".slab1", {scale: .8})}
                            >
                                <picture>
                                    <source srcSet={slab_3} type="image/webp" />
                                    <img src={slab_3_FALLBACK} alt='We Create'/>
                                </picture>
                                <h2>{slabTitle_3}</h2>
                            </motion.div>
                        </div>
                    </Link>
                </Parallax>
            </div>
        </>
    );
}
export default Slabs;
