import * as React from 'react';
import {useEffect, useRef} from "react";
import Lottie from "lottie-react";
import {useInView} from "react-intersection-observer";
import {Link} from 'react-scroll';
import {Fade} from "react-awesome-reveal";
import traffic from '../media/icons/salesDot.json';
import bottom_accent from '../media/accents/clapboard-accent-two.png';
import promo_1 from '../media/images/marketing_promo_final_edit.webp';
import promo_1_FALLBACK from '../media/images/marketing_promo_final_edit.png';
import {CaretDownOutlined} from "@ant-design/icons";
import {Parallax} from "react-scroll-parallax";
import {isMobile} from "react-device-detect";
const WhyProduct = () => {
    const options = {
        threshold: 0.4,
    }
    const optionsTwo = {
        threshold: 0.15,
    }
    const [ref, inView] = useInView(options);
    const [ ref2, inView2 ] = useInView(optionsTwo);
    const refAnim1 = useRef();
    const bar1 = useRef();
    const bar2 = useRef();
    const bar3 = useRef();
    const progressOne = 88;
    const progressTwo = 80;
    const progressThree = 95;
    useEffect(() => {
        if(inView2){
            bar1.current?.style.setProperty('animation-play-state', 'running');
            bar2.current?.style.setProperty('animation-play-state', 'running');
            bar3.current?.style.setProperty('animation-play-state', 'running');
        } else {
            bar1.current?.style.setProperty('animation-play-state', 'paused');
            bar2.current?.style.setProperty('animation-play-state', 'paused');
            bar3.current?.style.setProperty('animation-play-state', 'paused');
        }
    },[inView2]);
    useEffect(() => {
        if(inView){
            refAnim1.current?.play();
        } else {
            refAnim1.current?.pause();
        }
    }, [inView]);
    return (
        <>
            <div className='section5' >
                <Parallax disabled={isMobile} speed={isMobile ? 0 : 55} className="container-fluid display-no-title">
                    <p className="text display-no-title">WHY VIDEO</p>
                </Parallax>
                <div className={'section5-tiers'}>
                    <div className={'spacer-5'}>&nbsp;</div>
                    <div className='tier-item tier-item-top-margin' id={'market-growth'}>
                        <h2><font className={'apple-blue-reverse'}>Unleash video content power</font> and engage audiences <font className={'apple-blue-reverse'}>like never before</font></h2>
                        <h3>Stay ahead of your competition and gain a competitive edge within today's market, see how below:</h3>
                    </div>
                    <Fade triggerOnce={true} cascade={false}>
                        <div className={'progress-bar-container'}>
                            <div className={'skill-bars'} ref={ref2}>
                                <h3><b style={{color: 'white'}}>{progressTwo}% of businesses</b> found that <br/>videos directly helped them <b style={{color: 'white'}}>increase sales</b></h3>
                                <div className="bar">
                                    <div className="progress-line barOne" >
                                        <span ref={bar1}></span>
                                    </div>
                                </div>
                                <h3><b style={{color: 'white'}}>{progressOne}% of clients</b> who use <br/>video are <b style={{color: 'white'}}>satisfied with their ROI</b></h3>
                                <div className="bar">
                                    <div className="progress-line barTwo" >
                                        <span ref={bar2}></span>
                                    </div>
                                </div>
                                <h3><b style={{color: 'white'}}>{progressThree}% of businesses and professionals</b><br/>planned to <b style={{color: 'white'}}>increase their budgets</b> for video ads</h3>
                                <div className="bar">
                                    <div className="progress-line barThree" >
                                        <span ref={bar3}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Link className='slab5-project slab5-project-why' to='contact-us' smooth={false}>
                        <button type='button'>Let's harness that power</button>
                    </Link>
                    <div className={'spacer-5'}>&nbsp;</div>
                    <div className={'tier-item'}>
                        <picture>
                            <source srcSet={promo_1} type="image/webp"/>
                            <img className={'social-animation'} src={promo_1_FALLBACK} alt={'Video marketing promo'}/>
                        </picture>
                    </div>
                    <div className='tier-item' id={'sales'}>
                        <h2><font className={'apple-blue-reverse'}>Increase</font> your <font className={'apple-blue-reverse'}>ROI</font> and <font className={'apple-blue-reverse'}>ROAS</font></h2>
                        <h3>Utilise video to create a personal connection with your audience, driving up engagement, traffic and leads</h3>
                        <p>We specialize in maximizing your ROI and ROAS per video, ensuring that every investment you make in video production yields maximum returns.</p>
                        <p>To achieve this, we use impact driven strategies, innovative techniques and advanced equipment, bringing fresh ideas to every project we undertake.</p>
                        <p>Our goal is to create videos that not only capture your audience's attention but also leave a lasting impression, driving your business forward.</p>
                    </div>
                    <Link className='slab5-project slab5-project-why' to='contact-us' smooth={false}>
                        <button type='button'>Book a consultation now</button>
                    </Link>
                    <div className={'spacer-5'}>&nbsp;</div>
                    <div className='tier-item tier-item-adjust-pos' ref={ref}>
                        <Lottie className={'social-animation'} lottieRef={refAnim1} animationData={traffic} autoplay={false} loop={true}/>
                    </div>
                    <div className='tier-item' id={'social-media'}>
                        <h2><font className={'apple-blue-reverse'}>Sky rocket</font> to success <br/>with <font className={'apple-blue-reverse'}>social media marketing</font></h2>
                        <h3>We bring your brand to life through dynamic video content and strategy</h3>
                        <p>Our team of creative professionals produces quality, impactful content in various formats that sets you apart from your competition. </p>
                        <p>Integrated social media campaigns tailored to different platforms can improve engagement and brand loyalty, leading to increased brand awareness and revenue.</p>
                        <p>By using multiple marketing channels and tactics, we can create a cohesive campaign that drives real results for your business.</p>
                        <p>Let us help you elevate your marketing efforts with a personalized integrated campaign today.</p>
                    </div>
                    <Link className='slab5-project slab5-project-why' to='contact-us' smooth={false}>
                        <button type='button'>Let's work on your campaign</button>
                    </Link>
                            <Link to='video-format' className='limited-border no-border-button-shadow no-bg-hover slab5-project' smooth={true}>
                                <button type='button' className='no-button-text'>Find out more below</button>
                            </Link>
                    <Link className='limited-border no-bg-hover slab5-project' to='video-format' smooth={'easeInOutQuad'}>
                        <div className={'arrow-down-projects'}><CaretDownOutlined /></div>
                    </Link>
                </div>
                <div className={'bottom-accent-icon'}>
                    <img src={bottom_accent} alt='bottom accent'/>
                </div>
            </div>
        </>
    )
}

export default WhyProduct;
