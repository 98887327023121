import * as React from "react";
import loadingLogo from "../media/images/main_logo_svg.svg";
import {Link} from 'react-scroll';
import studioPancakeImage from "../media/images/studiopancakelogo.webp";
import {InstagramOutlined, MailOutlined, PhoneOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {
    MENU_ITEM_1,
    MENU_ITEM_2,
    MENU_ITEM_3,
    MENU_ITEM_5,
    MENU_ITEM_6,
    EMAIL,
    PHONE_NUMBER,
    TEL_NUMBER,
    WHATSAPP,
    YEAR,
    MENU_ITEM_7, MENU_ITEM_8
} from "../constants/polarisData";
const FooterSection = () => {
    return(
        <>
            <div className='footer'>
                <div className='main-flex-tiers'>
                    <div className='footer-tier'>
                        <Link className='footer-logo' to="hero" spy={true} smooth={false}>
                            <img src={loadingLogo} alt='logo'/>
                            <h3 aria-label={'LONDON, SURREY, WORLDWIDE'}>LONDON | SURREY | WORLDWIDE</h3>
                        </Link>
                        <div className='footer-social'>
                            <ol>
                                <Link to="hero" spy={true} smooth={false}>
                                    <h2>{MENU_ITEM_1}</h2>
                                </Link>
                                <Link to="slabs" spy={true} smooth={false}>
                                    <h2>{MENU_ITEM_2}</h2>
                                </Link>
                                <Link to="projects" spy={true} smooth={false}>
                                    <h2>{MENU_ITEM_3}</h2>
                                </Link>
                                <Link to="why-video" spy={true} smooth={false}>
                                    <h2>{MENU_ITEM_7}</h2>
                                </Link>
                                <Link to="video-format" spy={true} smooth={false}>
                                    <h2>{MENU_ITEM_6}</h2>
                                </Link>
                                <Link to="why-us" spy={true} smooth={false}>
                                    <h2>{MENU_ITEM_8}</h2>
                                </Link>
                                <Link to="contact-us" spy={true} smooth={'easeInOutQuad'}>
                                    <h2>{MENU_ITEM_5}</h2>
                                </Link>
                            </ol>
                        </div>
                        <div className={'row-form'}>
                            <a className={'form-button'}
                               href={TEL_NUMBER}
                               rel="noopener noreferrer">
                                <button className={'call-button'} type={"button"}><p><PhoneOutlined /> {PHONE_NUMBER}</p></button>
                            </a>
                        </div>
                        <div className={'row-form'}>
                            <a className={'form-button'}
                               aria-label="Chat on WhatsApp" href={WHATSAPP} rel="noopener noreferrer"
                               target="_blank">
                                <button className={'whatsapp-button'} type={"button"}><p><WhatsAppOutlined/> Chat on WhatsApp</p></button>
                            </a>
                        </div>
                        <div className='footer-social-buttons'>
                            <a href={WHATSAPP} rel="noopener noreferrer"
                               target="_blank">
                                <WhatsAppOutlined />
                            </a>
                            <a href={EMAIL} rel="noopener noreferrer"
                               target="_blank">
                                <MailOutlined />
                            </a>
                            <a href='https://www.instagram.com/sulaimaanhaq/' rel="noopener noreferrer"
                               target="_blank">
                                <InstagramOutlined />
                            </a>
                        </div>
                        <div className='footer-site-text'>
                            <a href='https://privacy-policy-2dbcf1.netlify.app/' rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                        </div>
                    </div>
                    <div className='footer-tier'>
                        Exclusively<br/>Designed and Created By
                        <br/>
                        <div className={'footer-tier'}>
                            <div className='footer-social' style={{position: "relative", paddingTop: 13}}>
                                <a href="https://www.studiopancake.co.uk" rel="noopener noreferrer"
                                   target="_blank">
                                    <div className='studio-pancake-logo-footer'><img
                                        src={studioPancakeImage}
                                        alt="Studio Pancake Logo"
                                    /></div>
                                </a>
                            </div>
                        </div>
                        All Rights Reserved.
                        <br/>
                        Polaris © {YEAR}
                    </div>
                </div>
            </div>
        </>
    );
}
export default FooterSection;
