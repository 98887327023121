import * as React from 'react';
import logoARRI from "../media/images/company-logos/biohorizon.png";
import logoRED from "../media/images/company-logos/nhs.png";
import logoDJI from "../media/images/company-logos/queenmary.png";
import logoSONY from "../media/images/company-logos/studiopancakesquare.png";
const TrustedBy = ({heading_text}) => {
    return (
        <>
            <div className={'section-trusted-by'}>
                <div className='section-trusted-by-container'>
                    <h3>{heading_text ? heading_text : 'N/A'}</h3>
                    <div>
                        <div className={'row-logos'}>
                            <img className={'powered-by-logo scale-down-qm-logo trusted-by-margins'} src={logoDJI} alt={'Queen Mary logo'}/>
                            <img className={'powered-by-logo scale-down-logo trusted-by-margins'} src={logoRED} alt={'NHS logo'}/>
                            <img className={'powered-by-logo scale-up-bh-logo trusted-by-margins'} src={logoARRI} alt={'BioHorizon logo'}/>
                            <img className={'powered-by-logo trusted-by-margins'} src={logoSONY} alt={'Studio Pancake logo'}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TrustedBy;
