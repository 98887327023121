import * as React from 'react';
import {useInView} from "react-intersection-observer";
import {useEffect, useRef} from "react";
import Lottie from "lottie-react";
import {Link} from "react-scroll";
import ai from '../media/icons/ai.json';
import dna from '../media/icons/dna.json';
import doc from '../media/icons/doc.json';
import {CaretDownOutlined} from "@ant-design/icons";
import {Parallax} from "react-scroll-parallax";
import {isMobile} from "react-device-detect";
const WhyUs = () => {
    const options = {
        threshold: 0.2,
    }
    const [ ref, inView ] = useInView(options);
    const [ ref2, inView2 ] = useInView(options);
    const [ ref3, inView3 ] = useInView(options);
    const refAnim1 = useRef();
    const refAnim2 = useRef();
    const refAnim3 = useRef();
    useEffect(() => {
        if(inView) {
            refAnim1.current?.play();
        }else if(!inView){
            refAnim1.current?.pause();
        }
    }, [inView]);
    useEffect(() => {
        if(inView2){
            refAnim2.current?.play();
        }else if(!inView2){
            refAnim2.current?.pause();
        }
    }, [inView2]);
    useEffect(() => {
        if(inView3) {
            refAnim3.current?.play();
        } else if(!inView3){
            refAnim3.current?.pause();
        }
    }, [inView3]);
    return (
        <>
            <div className='section5 section5-padding-override'>
                <Parallax disabled={isMobile} speed={isMobile ? 0 : 55} className="container-fluid display-no-title">
                    <p className="text display-no-title">WHY POLARIS</p>
                </Parallax>
                <div className={'section5-tiers'}>
                    <div className={'spacer-5'}>&nbsp;</div>
                    <div className='tier-item' ref={ref}>
                        <Lottie lottieRef={refAnim1} animationData={dna} className='iconStyle3' autoplay={false} loop={true} />
                        <h2>Do you want an <font className={'apple-blue-reverse'}>expert team</font> driving your campaign to success?</h2>
                        <h3>We leverage collective expertise, resources and proven strategies to drive successful results for your business</h3>
                        <p>We're not just any production team, we're an unstoppable force driven by research, creativity, and an unrelenting desire to produce successful outcomes.</p>
                        <p>With years of experience and a passion for excellence, our team of specialists are dedicated to delivering market-leading results across numerous industries.</p>
                        <p>Your project will be meticulously crafted with the utmost attention to detail, ensuring that every aspect is optimized for maximum impact and success.</p>
                    </div>
                    <Link className='slab5-project slab5-project-why' to="contact-us" smooth={false}>
                        <button type='button'>Talk to an expert now</button>
                    </Link>
                    <Link to='market-driven' className='limited-border-2 no-bg-hover normal-text-button bottom-20' smooth={true}>
                        <button id={'team'} type='button' className='no-button-text'>I want to know more</button>
                    </Link>
                    <div className={'spacer-5'}>&nbsp;</div>
                    <div className='tier-item tier-item-top-margin' id={'market-driven'} ref={ref2}>
                        <Lottie lottieRef={refAnim2} animationData={doc} className='iconStyle' autoplay={false} loop={true} />
                        <h2>We love <font className={'apple-blue-reverse'}>market driven</font> storytelling</h2>
                        <h3>Create a connection with your audience and drive desired outcomes</h3>
                        <p>We understand that every brand and business has a unique message and target audience.</p>
                        <p>We work for you to craft visually stunning and impactful videos that speak directly to your market.</p>
                        <p>Through extensive market research and analysis, we develop a deep understanding of your target audience, we craft compelling stories that drive engagement and connect with your target customers.</p>
                    </div>
                    <Link className='slab5-project slab5-project-why' to="contact-us" smooth={false}>
                        <button type='button'>Let's work on your story</button>
                    </Link>
                    <Link to='video-format' className='limited-border-2 no-bg-hover normal-text-button bottom-20' smooth={true}>
                        <button id={'team'} type='button' className='no-button-text'>What services can I expect?</button>
                    </Link>
                    <div className={'spacer-5'}>&nbsp;</div>
                    <div className='tier-item tier-item-top-margin' ref={ref3}>
                        <Lottie lottieRef={refAnim3} animationData={ai} className='aiStyle' autoplay={false} loop={true} />
                        <h2>We use <font className={'apple-blue-reverse'}>cutting edge</font> technology</h2>
                        <h3>High-quality cameras and audio equipment to advanced video editing software and AR</h3>
                        <p>At Polaris Studio, we strive to produce innovative and boundary pushing projects through creative experimentation.</p>
                        <p>Our use of quality equipment is paired with a constant search for new technologies and techniques to enhance our work and set us apart from the competition.</p>
                        <p>No matter the project, we are dedicated to delivering stunning content that will put you ahead of your competitors.</p>
                    </div>
                    <Link className='slab5-project slab5-project-why' to="contact-us" smooth={false}>
                        <button type='button'>Let's get started now</button>
                    </Link>
                    <Link to='section-reviews' className='limited-border-2 no-bg-hover normal-text-button' smooth={true}>
                        <button id={'team'} type='button' className='no-button-text'>What do people say?</button>
                    </Link>
                    <Link className='limited-border no-bg-hover slab5-project' to='section-reviews' smooth={'easeInOutQuad'}>
                        <div className={'arrow-down-projects'}><CaretDownOutlined /></div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default WhyUs;
