import * as React from 'react';
import {useScrollListener, NavMenuButton} from "../index";
import Link from 'react-scroll/modules/components/Link';
import mainLogo from '../../media/images/main_logo_forty.svg';
import {
    MENU_ITEM_1, MENU_ITEM_1_REF,
    MENU_ITEM_2, MENU_ITEM_2_REF,
    MENU_ITEM_3, MENU_ITEM_3_REF,
    MENU_ITEM_5, MENU_ITEM_5_REF,
    MENU_ITEM_6, MENU_ITEM_6_REF,
    MENU_ITEM_7, MENU_ITEM_7_REF,
    MENU_ITEM_8, MENU_ITEM_8_REF
} from "../../constants/polarisData";
import {useEffect, useState} from "react";
const Navbar = ({props}) => {
    const [inView, setInView] = useState(null);
    const [clicked, setClicked] = useState(false);
    const scrollDirection = useScrollListener();
    const navStyle = {
        active: {
            visibility: "visible",
            transition: "all 0.5s ease-in-out"
        },
        hidden: {
            visibility: "hidden",
            transition: "all 0.5s ease-in-out",
            transform: "translateY(-150%)"
        }
    };
    const refreshPage = () => {
        window.location.reload();
    }
    const onButtonClick = () => {
        setClicked(true);
    }
    useEffect(() => {
        if (clicked) {
            document.body.querySelector('.section1Video').classList.add('darken-video-hero');
        }
    }, [clicked]);

    useEffect(() => {
        if(props.refProjectSection || props.refContactSection){
            setInView(true)
        } else {
            setInView(false);
        }
    }, [props.refProjectSection, props.refContactSection]);
    return (
        <>
            <nav>
                <div className="nav-container" style={scrollDirection === "up" && !inView ?  navStyle.active : navStyle.hidden}>
                    <div className='navbar'>
                        <Link onClick={refreshPage} to={MENU_ITEM_1_REF} spy={true} smooth={false}
                              className='navbar-title navbar-item-logo'  aria-label="Polaris Studio Logo"><img src={mainLogo} width="640" height="360" alt="logo"/>
                        </Link>
                        <Link to={MENU_ITEM_1_REF} spy={true} smooth={false}
                              className='navbar-item' aria-label="Home">{MENU_ITEM_1}
                        </Link>
                        <Link  to={MENU_ITEM_2_REF} spy={true} smooth={false}
                              className='navbar-item' aria-label="Who we are">{MENU_ITEM_2}
                        </Link>
                        <Link to={MENU_ITEM_3_REF} spy={true} smooth={false} onClick={onButtonClick}
                              className='navbar-item' aria-label="Portfolio">{MENU_ITEM_3}
                        </Link>
                        <Link to={MENU_ITEM_7_REF} spy={true} smooth={false} onClick={onButtonClick}
                               className='navbar-item' aria-label="Why Video">{MENU_ITEM_7}
                        </Link>
                        <Link to={MENU_ITEM_6_REF} spy={true} smooth={false} onClick={onButtonClick}
                               className='navbar-item' aria-label="Formats">{MENU_ITEM_6}
                        </Link>
                        <Link to={MENU_ITEM_8_REF} spy={true} smooth={false} onClick={onButtonClick}
                               className='navbar-item' aria-label="Why Us">{MENU_ITEM_8}
                        </Link>
                        <Link to={MENU_ITEM_5_REF} spy={true} smooth={false} onClick={onButtonClick}
                               className='navbar-item-last' aria-label="Get in touch">{MENU_ITEM_5}
                        </Link>
                        <NavMenuButton scrollDirection={scrollDirection} />
                    </div>
                </div>
            </nav>
        </>
    );
};
export default Navbar;
