const currentYear = new Date().getFullYear();
export const PHONE_NUMBER = '07830386748';
export const TEL_NUMBER = 'tel:07830386748';
export const EMAIL = 'mailto:sulaimaan@polarisstudio.co.uk';
export const MAIL = 'sulaimaan@polarisstudio.co.uk';
export const CALENDLY = 'https://calendly.com/polarisstudio/30min';
export const GREVIEW = 'https://g.co/kgs/KNefip8';
export const SCORECARD = 'https://polaris-xfrvtphg.scoreapp.com';
export const WHATSAPP = 'https://wa.me/447830386748?text=I%20want%20to%20discuss%20a%20project';
export const INSTAGRAM = 'https://www.instagram.com/polaris.studio_';
export const FACEBOOK = 'https://www.facebook.com/';
export const YOUTUBE = '';
export const ORIGIN = '&origin=https://polaris-studio-v1.netlify.app/';
export const YEAR = currentYear;

/* MENU ITEMS (NAMES)*/
export const MENU_ITEM_1 = 'HOME';
export const MENU_ITEM_1_REF = 'hero';
export const MENU_ITEM_2 = 'WHO WE ARE';
export const MENU_ITEM_2_REF = 'slabs';
export const MENU_ITEM_3 = 'OUR WORK';
export const MENU_ITEM_3_REF = 'projects';
export const MENU_ITEM_5 = 'GET IN TOUCH';
export const MENU_ITEM_5_REF = 'contact-us';
export const MENU_ITEM_6 = 'SERVICES & FORMATS';
export const MENU_ITEM_6_REF = 'video-format';
export const MENU_ITEM_7 = 'WHY VIDEO';
export const MENU_ITEM_7_REF = 'why-video';
export const MENU_ITEM_8 = 'WHY US';
export const MENU_ITEM_8_REF = 'why-us';

/* PROJECT URLS */
export const PROJECT_TITLE_0 = 'Showreel';
export const SHOWREEL = 'https://www.youtube.com/watch?v=4qMLODM10o4';
//old version https://youtu.be/AFZNCNzH5ME';
export const PROJECT_TITLE_1 = 'Love At First Bite';
export const PROJECT_LINK_1 = 'https://youtu.be/cAgMMRROVQs';
export const PROJECT_TITLE_2 = 'The Luthier';
export const PROJECT_LINK_2 = 'https://youtu.be/dCysgrelcd4';
export const PROJECT_TITLE_3 = 'What Is Greatness?';
export const PROJECT_LINK_3 = 'https://youtu.be/ESfHAuzMsfg';
export const PROJECT_TITLE_4 = 'Hit That Spot';
export const PROJECT_LINK_4 = 'https://youtu.be/qr9SVUbSRKU';
export const PROJECT_TITLE_5 = 'PROJECT 5';
export const PROJECT_LINK_5 = 'https://www.youtube.com/watch?v=s9M30w085SY';
export const FALL_BACK_LINK = 'https://www.youtube.com/watch?v=eXju5LkrYs4';

