import * as React from 'react';
import loadingLogo from "../../media/images/main_logo_svg.svg";

export function PreLoader({ whichState }) {
        return (
            whichState ?
                <div className='pre-loader'>
                    <img src={loadingLogo} className='pre-loader-icon no-anim' alt='loading start' />
                </div>
                :
                <div className='pre-loader'>
                    <img src={loadingLogo} className='pre-loader-icon' alt='loading animation' />
                </div>
        );
}

export default PreLoader;
