import * as React from 'react';
import {CALENDLY, EMAIL, TEL_NUMBER, WHATSAPP} from "../constants/polarisData";
import {
    PhoneOutlined,
    WhatsAppOutlined,
    MailOutlined,
    CaretDownOutlined,
    CalendarOutlined
} from "@ant-design/icons";
import {Link} from "react-scroll";
import MAIN_VIDEO_3 from "../media/video/test_3.mp4";
import MAIN_VIDEO_3_HQ from '../media/video/test_3_HQ.mp4';
import {Spinner} from "./index";
import {useInView} from "react-intersection-observer";
import {motion} from "framer-motion";
import {useEffect, useRef, useState} from "react";
import {isMobile} from "react-device-detect";
const QuoteSection = () => {
    const options = {
        threshold: 0.2,
    }
    const { ref, inView } = new useInView(options);
    const videoRef = useRef();
    const Variants = {
        offscreen: {
            scale: 1.5,
        },
        onscreen: {
            scale: 0.7,
            transition: {
                ease: "easeQuadOut",
                type: "spring",
                bounce: 0.1,
                delay: 0.1,
                duration: 3,
            }
        }
    };
    const VariantsReversed = {
        offscreen: {
            y: 0,
            x: 0,
            scale: 0.75,
        },
        onscreen: {
            y: 0,
            x: 0,
            scale: 1,
            transition: {
                ease: "easeQuadOut",
                type: "spring",
                bounce: 0.1,
                delay: 0.1,
                duration: 3,
            }
        }
    };
    const refreshPage = (e)=>{
        console.log('REFRESHING PAGE' + e);
    }
    const playVideo = () => { videoRef.current?.play(); }
    const pauseVideo = () => { videoRef.current?.pause(); }
    const [isPreloading, setIsPreloading] = useState(true);
    useEffect(() => {
        if (inView) {
            playVideo();
        }
        else {
            pauseVideo();
        }
        if(inView && videoRef.current?.paused) {
            playVideo();
        }
    }, [inView]);
    useEffect(() => {
        if(isPreloading) {
            console.log('BTS VIDEO: LOADING');
        } else {
            console.log('BTS VIDEO: LOADED');
        }
    }, [isPreloading]);
    useEffect(() => {
        if(videoRef.current?.onLoadStart) {
            setIsPreloading(true);
        } else {
            setIsPreloading(false);
        }
    }, []);
    return (
        <>
            <div className={isMobile ? 'quote-container' : 'quote-container border-radius-video'} ref={ref}>
                {isMobile ?
                    <>
                        <motion.video
                            width={1920}
                            height={1080}
                            src={MAIN_VIDEO_3}
                            muted={true}
                            playsInline={true}
                            ref={videoRef}
                            autoPlay={true}
                            loop={true}
                            controls={false}
                            preload="metadata"
                            onError={(e) => refreshPage(e)}
                            initial={VariantsReversed.offscreen}
                            whileInView={VariantsReversed.onscreen}
                            viewport={{ once: true, amount: 0.15 }}
                        />
                    </>
                    :
                    <>
                        <motion.video
                            width={1920}
                            height={1080}
                            src={MAIN_VIDEO_3_HQ}
                            muted={true}
                            playsInline={true}
                            ref={videoRef}
                            autoPlay={true}
                            loop={true}
                            controls={false}
                            preload="metadata"
                            onError={(e) => refreshPage(e)}
                            initial={Variants.offscreen}
                            whileInView={Variants.onscreen}
                            viewport={{ once: true, amount: 0.15 }}
                        />
                    </>
                }
                {isPreloading ? <div className='spinner'><Spinner /></div> : null}
                <h1>Let's Connect Today</h1>
                <div className={'quote-button-container'}>
                    <div className={'quote-column'}>
                        <div className={'quote-button'}>
                            <a href={TEL_NUMBER} rel="noopener noreferrer">
                                <button className={'call-button'}><PhoneOutlined/>&nbsp;&nbsp;Call Now</button>
                            </a>
                        </div>
                        <div className={'quote-button'}>
                            <a aria-label="Chat on WhatsApp" href={WHATSAPP} rel="noopener noreferrer" target="_blank">
                                <button className={'whatsapp-button'}><WhatsAppOutlined/>&nbsp;&nbsp;WhatsApp</button>
                            </a>
                        </div>
                    </div>
                    <div className={'quote-column'}>
                        <div className={'quote-button'}>
                            <a aria-label="Book Via Calendly" href={CALENDLY} rel="noopener noreferrer" target="_blank">
                                <button className={'calendly-button'}><CalendarOutlined/>&nbsp;&nbsp;Book Now
                                </button>
                            </a>
                        </div>
                        <div className={'quote-button'}>
                            <a aria-label="Email Us Directly" href={EMAIL} rel="noopener noreferrer" target="_blank">
                                <button className={'email-button'}><MailOutlined/>&nbsp;&nbsp;Email</button>
                            </a>
                        </div>
                    </div>
                </div>
                <Link to='reviews' className='slab5-project slab5-project-white' smooth={'easeInOutQuad'}>
                    <button type='button'>What do people say?</button>
                </Link>
                <Link to='why-us' className='no-border-button-shadow limited-border no-bg-hover  slab5-project ' smooth={'easeInOutQuad'}>
                    <button type='button'>Find out more below</button>
                </Link>
                <Link className='limited-border no-bg-hover slab5-project' to='why-us' smooth={'easeInOutQuad'}>
                    <div className={'arrow-down-projects'}><CaretDownOutlined /></div>
                </Link>
            </div>
        </>
    )
}
export default QuoteSection;
