import * as React from 'react';
import Link from 'react-scroll/modules/components/Link';
import {useScrollListener} from "../index";
import {PhoneOutlined, FormOutlined, MailOutlined, MinusOutlined} from '@ant-design/icons';
import {useEffect} from "react";

const ContactButton = ({props}) => {
    const [inView, setInView] = React.useState(null);
    const scrollDirection = useScrollListener();
    const contactButtonStyle = {
        active: {
            visibility: "visible",
            transition: "all 0.5s ease"
        },
        hidden: {
            visibility: "hidden",
            transition: "all 0.5s ease",
            transform: "translateX(100%)"
        }
    }
    useEffect(() => {
        if(props.refHero || props.refProjectSection || props.refContactSection){
            setInView(true)
        } else {
            setInView(false);
        }
    }, [props.refHero, props.refProjectSection, props.refContactSection]);

        return (
            <>
                    <Link to="contact-us" spy={true} smooth={false} aria-label="Contact us button">
                        <div className='contact-button-container'
                             style={scrollDirection === 'up' && !inView ?  contactButtonStyle.active : contactButtonStyle.hidden}>
                            <button className='contact-button'>
                                <i><PhoneOutlined/></i>
                                <i><MinusOutlined /></i>
                                <i><MailOutlined /></i>
                                <i><MinusOutlined /></i>
                                <i><FormOutlined /></i>
                            </button>
                        </div>
                    </Link>
            </>
        );
};

export default ContactButton;
